import {Action, createSelector, State, StateContext} from '@ngxs/store';
import {GetCountryStates} from './countries-states.actions';
import {GeographyApiService} from '@matchsource/api/geography';
import {catchError, tap} from 'rxjs/operators';
import {patch} from '@ngxs/store/operators';
import {of} from 'rxjs';
import orderBy from 'lodash-es/orderBy';
import {Injectable} from '@angular/core';
import {CountryStateModel} from '@matchsource/models/country';

interface CountryStatesStateModel {
  loading: boolean;

  loaded: boolean;

  countryStates: CountryStateModel[];

  error: any;
}

export interface CountriesStatesStateModel {
  [countryCode: string]: CountryStatesStateModel;
}

const DEFAULT_COUNTRY_STATES_STATE: CountryStatesStateModel = {
  loading: false,

  loaded: false,

  countryStates: null,

  error: null,
};

@State<CountriesStatesStateModel>({
  name: 'countriesStates',
  defaults: {},
})
@Injectable()
export class CountriesStatesState {
  static countryStates(countryCode: string) {
    return createSelector([CountriesStatesState], (state: CountryStatesStateModel) => {
      const countryStatesModel = state[countryCode] || DEFAULT_COUNTRY_STATES_STATE;

      return countryStatesModel.countryStates;
    });
  }

  static loading(countryCode: string) {
    return createSelector([CountriesStatesState], (state: CountryStatesStateModel) => {
      const countryStatesModel = state[countryCode] || DEFAULT_COUNTRY_STATES_STATE;

      return countryStatesModel.loading;
    });
  }

  constructor(private readonly geographyApi: GeographyApiService) {}

  @Action(GetCountryStates)
  getCountryStates({getState, setState}: StateContext<CountriesStatesStateModel>, {countryCode}: GetCountryStates) {
    const state = getState();
    const countryState = state[countryCode];

    if (countryState && (countryState.loading || countryState.loaded)) {
      return;
    }

    setState(
      patch<{[key: string]: CountryStatesStateModel}>({
        [countryCode]: {
          loading: true,
          loaded: false,
          countryStates: null,
          error: null,
        },
      })
    );

    return this.geographyApi.getStates(countryCode).pipe(
      catchError(error => {
        setState(
          patch({
            [countryCode]: patch({
              error,
            }),
          })
        );

        return of(null);
      }),
      tap(countryStates => {
        setState(
          patch({
            [countryCode]: patch({
              loading: false,
              loaded: true,
              countryStates: orderBy(countryStates, countryStateItem => countryStateItem.code),
            }),
          })
        );
      })
    );
  }
}
